.button-group {
  position: absolute;
  top: 0px;
  left: 0px;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  z-index: 1;
}

.action-cover:hover .action-button {
  display: flex;
}

.action-button {
  @apply xl:hidden 2xl:hidden;
}

.action-button {
  background-color: white;
  border: 0;
  padding: 0;
  min-width: 44px;
  min-height: 44px;
  align-items: center;
}

.action-button:hover {
  background-color: white;
  color: var(--primary-color);
}

.text-number {
  font-size: 15px;
  line-height: 19px;
  max-width: 60px;
  min-width: 48px;
  border-radius: 0px;
  font-family: var(--font-wixmadefordisplay);
}
.text-number input {
  text-align: center !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.button-plus-minus {
  padding: 0px;
  font-weight: 400 !important;
  font-size: 28px;
  display: flex;
  align-items: center;
  font-family: Arial;
}

.input-text-primary,
.input-text-primary .ant-input-number-input {
  color: var(--primary-color) !important;
}

.input-text-primary:hover {
  color: var(--primary-color) !important;
}

.search-text-hint {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}
@media (max-width: 1919px) {
  .button-plus-minus {
    font-size: 24px;
    line-height: 30px;
  }
  .button-plus-minus {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .text-number {
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
  }
  .button-plus-minus {
    font-size: 20px;
  }
  .action-button {
    min-width: 32px;
    min-height: 32px;
  }
}
